<template>
	<div
		class="fixed h-full overflow-auto top-[5%] sm:top-8 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-top"
		v-show="active">
		<transition
			enter-active-class="transition ease-out duration-200"
			enter-class="opacity-0"
			enter-to-class="opacity-100"
			leave-active-class="transition ease-in duration-200"
			leave-class="opacity-100"
			leave-to-class="opacity-0">
			<div class="fixed inset-0 transition-opacity" v-if="active">
				<div class="absolute inset-0 bg-gray-900 opacity-75"></div>
			</div>
		</transition>
		<transition
			enter-active-class="transition ease-out duration-200"
			enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
			enter-to-class="opacity-100 translate-y-0 sm:scale-100"
			leave-active-class="transition ease-in duration-200"
			leave-class="opacity-100 translate-y-0 sm:scale-100"
			leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
			<focus-trap
				:active="active"
				:initial-focus="() => $refs.firstInput">
				<div>
					<div
						class="bg-white overflow-auto h-auto max-h-[90%] rounded-md px-4 pt-5 pb-4 shadow-md transform transition-all sm:max-w-lg sm:w-full sm:p-6"
						role="dialog"
						aria-modal="true"
						aria-labelledby="modal-headline"
						v-if="active"
						v-click-away="close">
						<div class="sm:flex sm:items-start">
							<div
								class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
								<SvgIcon
									class="text-blue-600"
									icon="link"
									aria-hidden="true" />
							</div>
							<div
								class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
								<h3
									class="text-lg leading-6 font-medium text-gray-900"
									id="modal-headline">
									Add Link
								</h3>
								<p class="text-sm leading-5 text-gray-500">
									Don&rsquo;t forget the https://
								</p>
							</div>
						</div>
						<div class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
							<label class="sr-only" for="urlInput">URL</label>
							<input
								ref="firstInput"
								name="url"
								id="urlInput"
								class="bg-gray-100 leading-10 rounded-lg px-2 w-full"
								v-model="url" />
						</div>
						<div class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
							<span class="flex w-full rounded sm:w-auto">
								<button
									type="button"
									class="button modal-button primary"
									@click="confirm">
									Save
								</button>
							</span>
							<span
								class="mt-3 flex w-full rounded sm:mt-0 sm:ml-3 sm:w-auto">
								<button
									type="button"
									class="button modal-button secondary"
									@click="close">
									Cancel
								</button>
							</span>
						</div>
					</div>
				</div>
			</focus-trap>
		</transition>
	</div>
</template>

<script lang="ts">
export default {
	props: {
		active: {
			type: Boolean,
			default: false,
		},
		modelValue: {
			type: String,
			default: "",
		},
	},
	data(): any {
		return {
			url: null,
		};
	},
	methods: {
		close() {
			this.$emit("close");
			this.$emit("returnFocus");
			document.body.classList.remove("no-scroll");
		},
		confirm() {
			this.$emit("confirm");
		},
		handleKeyDown(event: KeyboardEvent) {
			if (event.key === "Escape") {
				this.close();
			}
		},
		addKeyDownListener() {
			document.addEventListener("keydown", this.handleKeyDown);
		},
		removeKeyDownListener() {
			document.removeEventListener("keydown", this.handleKeyDown);
		},
	},
	mounted() {
		this.url = this.modelValue;
	},
	watch: {
		active(newValue) {
			if (newValue) {
				this.addKeyDownListener();
			} else {
				this.removeKeyDownListener();
			}
		},
		url(value) {
			this.$emit("update:modelValue", value);
		},
	},
};
</script>

<style scoped>
.modal-button {
	@apply inline-flex justify-center w-full rounded-lg border px-4 py-2 text-base leading-6 font-medium shadow-sm mr-0 transition ease-in-out duration-150 !important;
}

.primary {
	@apply bg-blue-600 text-white border-transparent !important;
}

.primary:hover {
	@apply bg-blue-500 !important;
}

.secondary {
	@apply bg-white text-gray-700 border-gray-300 !important;
}

.secondary:hover {
	@apply text-gray-500 !important;
}

@sm {
	.modal-button {
		@apply text-sm leading-5;
	}
}
</style>
