<template>
	<div>
		<slot name="trigger"></slot>
		<div
			class="fixed h-full overflow-auto top-[5%] sm:top-8 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-top"
			v-show="opened">
			<transition
				enter-active-class="transition ease-out duration-200"
				enter-class="opacity-0"
				enter-to-class="opacity-100"
				leave-active-class="transition ease-in duration-200"
				leave-class="opacity-100"
				leave-to-class="opacity-0">
				<div class="fixed inset-0 transition-opacity" v-if="opened">
					<div class="absolute inset-0 bg-gray-900 opacity-75"></div>
				</div>
			</transition>
			<transition
				enter-active-class="transition ease-out duration-200"
				enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				enter-to-class="opacity-100 translate-y-0 sm:scale-100"
				leave-active-class="transition ease-in duration-200"
				leave-class="opacity-100 translate-y-0 sm:scale-100"
				leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
				<focus-trap
					:active="opened"
					:initial-focus="() => $refs.firstInput">
					<div>
						<div
							class="bg-white h-auto max-h-[90%] overflow-auto rounded-md shadow-md transform transition-all sm:max-w-md sm:w-full"
							role="dialog"
							aria-modal="true"
							aria-labelledby="modal-headline"
							v-if="opened">
							<div
								v-if="heading"
								class="sm:flex sm:items-start justify-between border-b border-gray-100 rounded-t-md py-4 px-6">
								<div
									class="mt-3 text-center sm:mt-0 sm:text-left">
									<h3
										class="text-lg leading-9 font-medium text-gray-900"
										id="modal-headline">
										{{ heading }}
									</h3>
								</div>
								<div class="flex">
									<button
										type="button"
										class="flex items-center justify-center hover:bg-cyan-50 rounded-lg w-9 h-9 transition"
										@click="close"
										aria-label="Close">
										<SvgIcon
											icon="close"
											size="sm"
											weight="bold" />
									</button>
								</div>
							</div>
							<div class="bg-white p-6">
								<slot name="body"></slot>
							</div>
							<div
								v-if="$slots.actions"
								class="flex items-center bg-gray-50 border-t border-gray-100 rounded-b-md p-4">
								<span class="flex sm:w-auto">
									<slot name="actions"></slot>
								</span>
							</div>
						</div>
					</div>
				</focus-trap>
			</transition>
		</div>
	</div>
</template>

<script lang="ts">
import SvgIcon from "../SvgIcon.vue";

export default {
	components: { SvgIcon },

	props: {
		closeText: {
			type: String,
			default: "Cancel",
		},
		opened: {
			type: Boolean,
			default: false,
		},
		heading: {
			type: String,
			required: false,
		},
	},

	methods: {
		close() {
			this.$emit("close");
			this.$emit("returnFocus");
		},
		handleKeyDown(event: KeyboardEvent) {
			if (event.key === "Escape") {
				this.close();
			}
		},
		addKeyDownListener() {
			document.addEventListener("keydown", this.handleKeyDown);
		},
		removeKeyDownListener() {
			document.removeEventListener("keydown", this.handleKeyDown);
		},
	},
	mounted() {
		// No need to set the event listener here
	},
	beforeUnmount() {
		this.removeKeyDownListener();
		document.body.classList.remove("no-scroll");
	},
	watch: {
		opened(newValue) {
			if (newValue) {
				this.addKeyDownListener();
			} else {
				this.removeKeyDownListener();
			}
		},
	},
};
</script>
