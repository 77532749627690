<template>
	<div>
		<div class="text-sm text-gray-500 mb-4">
			<router-link to="/content/learn/courses">Courses</router-link>
			> Course Name > Module Name > Create Lesson
		</div>
	</div>
	<form
		@submit="submitForm"
		id="lessonForm"
		class="shadow rounded-x"
		v-if="lesson">
		<div
			class="flex flex-row flex-wrap gap-2 items-center px-6 py-2 bg-gray-200 rounded-t-md">
			<div class="text-xs flex flex-row items-center">
				<span class="font-semibold"
					>ID<span class="hidden md:inline-flex">:&nbsp;</span></span
				><span class="hidden md:inline-flex">{{ lesson.id }}</span>
				<CopyAction :value="lesson.id" />
			</div>
			<div class="text-xs flex flex-row items-center">
				<span class="font-semibold"
					>UUID<span class="hidden md:inline-flex"
						>:&nbsp;</span
					></span
				><span class="hidden md:inline-flex">{{ lesson.uuid }}</span>
				<CopyAction :value="lesson.uuid" />
				<span class="font-semibold"
					>Status<span class="hidden md:inline-flex"
						>:&nbsp;</span
					></span
				>
				<span
					v-if="isPublished"
					class="hidden font-bold md:inline-flex text-green-500"
					>{{ lesson.status.toUpperCase() }}</span
				>
				<span
					v-if="!isPublished"
					class="hidden font-bold md:inline-flex text-red-500"
					>DRAFT</span
				>
				<span class="hidden md:inline-flex">
					<ToggleSwitch
						v-model="isPublished"
						size="sm"
						@click="handleToggleClick"
						class="mx-2" />
				</span>
			</div>
		</div>
		<div class="bg-white space-y-6 py-9 px-6">
			<div class="grid md:grid-cols-2 gap-6">
				<div>
					<label
						for="title"
						class="flex items-center text-sm leading-7 font-medium ml-2 space-x-4">
						Title
					</label>
					<input
						type="text"
						name="title"
						id="title"
						v-model="lesson.title"
						class="h-12"
						required />
				</div>
				<div>
					<label
						for="slug"
						class="flex items-center text-sm leading-7 font-medium ml-2 space-x-4">
						Slug
					</label>
					<input
						type="text"
						name="slug"
						id="slug"
						v-model="lesson.slug"
						class="h-12"
						required />
				</div>
			</div>
			<div class="grid md:grid-cols-2 gap-6">
				<div>
					<label
						for="description"
						class="flex items-center text-sm leading-7 font-medium ml-2 space-x-4">
						Description
					</label>
					<textarea
						name="description"
						id="description"
						rows="8"
						class="bg-white rounded-lg w-full"
						v-model="lesson.description" />
				</div>
			</div>
			<div class="grid md:grid-cols-2 gap-6">
				<div>
					<label
						for="video_id"
						class="flex items-center text-sm leading-7 font-medium ml-2 space-x-4">
						Video
					</label>
					<WistiaPreviewer
						id="video_id"
						name="video_id"
						:videoPath="lesson.video_path"
						v-on:path-changed="updateVideo" />
				</div>
				<div>
					<label
						for="video_id"
						class="flex items-center text-sm leading-7 font-medium ml-2 space-x-4">
						Video Length (in seconds)
					</label>
					<input
						type="text"
						name="video_length"
						id="video_length"
						v-model="lesson.video_length"
						class="h-12" />
				</div>
			</div>
			<div class="grid md:grid-cols-2 gap-6">
				<div>
					<label
						for="quiz"
						class="flex items-center text-sm leading-7 font-medium ml-2 space-x-4">
						<div>Quiz</div>
						<div
							class="text-xs text-red-600 font-normal"
							v-if="error_msg">
							{{ error_msg }}
						</div>
					</label>
					<SelectDropdown
						v-model="lesson.quiz_id"
						name="quiz_id"
						theme="flat"
						:required="false">
						<option value="">Select an option</option>
						<option v-for="e in exam_options" :value="e.id">
							{{ e.name }}
						</option>
					</SelectDropdown>
				</div>
			</div>
			<div>
				<label
					for="content"
					class="flex items-center text-sm leading-7 font-medium ml-2 space-x-4">
					<div>Content</div>
				</label>
				<Editor name="content" ref="editor" :value="initial" />
			</div>
		</div>
		<div
			class="bg-gray-50 border-t border-gray-200 rounded-b-md p-6 flex justify-end">
			<button class="main button rounded-md" type="submit">Submit</button>
		</div>
	</form>
</template>
<script lang="ts">
import axios from "axios";
import { mapMutations } from "vuex";
import WistiaPreviewer from "../../../_elements/WistiaPreviewer.vue";
import SelectDropdown from "../../../_elements/SelectDropdown.vue";
import Editor from "../../../_elements/Editor.vue";
import ToggleSwitch from "@/components/_elements/ToggleSwitch.vue";

export default {
	components: { ToggleSwitch, WistiaPreviewer, SelectDropdown, Editor },
	props: {
		uuid: String,
	},
	data(): any {
		return {
			lesson: {
				content: null,
				description: null,
				id: null,
				uuid: "",
				quiz_id: null,
				slug: null,
				title: null,
				video_id: null,
				video_length: "",
				video_path: "",
				status: "",
			},
			exam_options: null,
			error_msg: null,
			initial: null,
		};
	},
	computed: {
		lessonContent() {
			return this.$store.state.admin.lessonContent;
		},
		isPublished() {
			return this?.lesson?.status === "published";
		},
	},
	watch: {
		lessonContent: function (val) {
			this.lesson.content = JSON.stringify(val);
		},
	},
	methods: {
		updateVideo(path, id) {
			this.lesson.video_path = path;
			this.lesson.video_id = id;
		},
		getLesson() {
			axios
				.get(`/api/admin/content/learn/lessons/${this.uuid}`)
				.then((response) => {
					this.exam_options = response.data.exam_options;
					this.lesson = response.data.lesson;
					this.initial = JSON.parse(this.lesson.content);
				});
		},
		getExamOptions() {
			axios.get(`/api/admin/content/learn/exams`).then((response) => {
				this.exam_options = response.data;
			});
		},
		handleToggleClick(e) {
			if (this.lesson.status === "published") {
				this.lesson.status = "draft";
			} else {
				this.lesson.status = "published";
			}

			this.submitForm(e);
		},
		submitForm(e) {
			e.preventDefault();
			axios
				.post(`/api/admin/content/learn/lessons/new`, this.lesson)
				.then((response) => {
					axios
						.post(
							`/api/admin/content/learn/modules/${this.uuid}/lessons/${response.data.uuid}`
						)
						.then((response) => {
							if (response.status < 400) {
								this.$router.push({
									name: "module_lessons",
									params: { uuid: this.uuid },
								});
							}
						})
						.catch((e) => {
							this.$events.$emit("toastNotification", {
								action: "saved",
								heading: "Error Saving Lesson",
								status: "error",
								message: e.response.data.message,
							});
						});
				})
				.catch((e) => {
					this.$events.$emit("toastNotification", {
						action: "saved",
						heading: "Error Saving Lesson",
						status: "error",
						message: e.response.data.message,
					});
				});
		},
		...mapMutations(["hideBladePage"]),
	},
	mounted() {
		this.hideBladePage();
		this.getExamOptions();
	},
};
</script>
