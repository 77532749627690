<template>
	<div>
		<div v-if="editor" class="tiptap-toolbar flex flex-col" :class="theme">
			<div class="flex-1 w-full flex flex-row">
				<button
					type="button"
					:class="{ 'is-active': editor.isActive('bold') }"
					:disabled="!editor.can().chain().focus().toggleBold().run()"
					@click="editor?.chain().focus().toggleBold().run()">
					<img src="/svg/editor/bold.svg" alt="" />
				</button>
				<button
					type="button"
					:class="{ 'is-active': editor.isActive('italic') }"
					:disabled="
						!editor.can().chain().focus().toggleItalic().run()
					"
					@click="editor?.chain().focus().toggleItalic().run()">
					<img src="/svg/editor/italic.svg" alt="" />
				</button>
				<button
					type="button"
					:class="{ 'is-active': editor.isActive('underline') }"
					:disabled="
						!editor.can().chain().focus().toggleUnderline().run()
					"
					@click="editor?.chain().focus().toggleUnderline().run()">
					<img src="/svg/editor/underline.svg" alt="" />
				</button>
				<button
					type="button"
					:class="{ 'is-active': editor.isActive('superscript') }"
					:disabled="
						!editor.can().chain().focus().toggleSuperscript().run()
					"
					@click="editor?.chain().focus().toggleSuperscript().run()">
					<img src="/svg/editor/superscript.svg" alt="" />
				</button>
				<button
					type="button"
					:class="{ 'is-active': editor.isActive('subscript') }"
					:disabled="
						!editor.can().chain().focus().toggleSubscript().run()
					"
					@click="editor?.chain().focus().toggleSubscript().run()">
					<img src="/svg/editor/subscript.svg" alt="" />
				</button>
				<div class="spacer"></div>
				<button
					id="linkButton"
					type="button"
					:class="{ 'is-active': editor.isActive('link') }"
					@click="showLinkModal(editor?.getAttributes('link'))">
					<img src="/svg/icons/link.svg" alt="" />
				</button>
				<!--                <button type="button" :class="{ 'is-active': isActive.link() }"-->
				<!--                        @click="showLinkModal(getMarkAttrs('link'))">-->
				<!--                    <img src="/svg/icons/link.svg" alt="">-->
				<!--                </button>-->
				<!--                // change link-->
				<!--            <button type="button" :class="{ 'is-active': editor.isActive('link') }"-->
				<!--                    :disabled="!editor.can().chain().focus().toggleLink().run()"-->
				<!--                    @click="showLinkModal(getMarkAttrs('link'))">-->
				<!--                <img src="/svg/icons/link.svg" alt="">-->
				<!--            </button>-->
				<div class="spacer"></div>
				<button
					type="button"
					:class="{ 'is-active': editor.isActive('bullet_list') }"
					:disabled="
						!editor.can().chain().focus().toggleBulletList().run()
					"
					@click="editor?.chain().focus().toggleBulletList().run()">
					<img src="/svg/icons/ul.svg" alt="" />
				</button>
				<button
					type="button"
					:class="{ 'is-active': editor.isActive('ordered_list') }"
					:disabled="
						!editor.can().chain().focus().toggleOrderedList().run()
					"
					@click="editor?.chain().focus().toggleOrderedList().run()">
					<img src="/svg/icons/ol.svg" alt="" />
				</button>
				<EditorLinkModal
					:active="data.linkModalIsActive"
					v-model="data.linkUrl"
					@close="hideLinkModal"
					@confirm="setLinkUrl(data.linkUrl)" />
				<EditorSourceModal
					:active="data.sourceModalIsActive"
					v-model="data.source"
					@close="hideSourceModal"
					@confirm="updateSource" />
				<button
					@click="
						editor
							.chain()
							.focus()
							.insertTable({
								rows: 3,
								cols: 3,
								withHeaderRow: true,
							})
							.run()
					">
					Insert Table
				</button>
			</div>
			<div v-if="editor.isActive('table')">
				<Button
					intent="flat"
					size="sm"
					@click="editor.chain().focus().addColumnBefore().run()">
					Add Col Before
				</Button>
				<Button
					intent="flat"
					size="sm"
					@click="editor.chain().focus().addColumnAfter().run()">
					Add Col After
				</Button>
				<Button
					intent="flat"
					size="sm"
					@click="editor.chain().focus().deleteColumn().run()">
					Delete Col
				</Button>
				<Button
					intent="flat"
					size="sm"
					@click="editor.chain().focus().addRowBefore().run()">
					Add Row Before
				</Button>
				<Button
					intent="flat"
					size="sm"
					@click="editor.chain().focus().addRowAfter().run()">
					Add Row After
				</Button>
				<Button
					intent="flat"
					size="sm"
					@click="editor.chain().focus().deleteRow().run()">
					Delete Row
				</Button>
				<Button
					intent="flat"
					size="sm"
					@click="editor.chain().focus().deleteTable().run()">
					Delete Table
				</Button>
				<Button
					intent="flat"
					size="sm"
					@click="editor.chain().focus().mergeCells().run()">
					Merge
				</Button>
				<Button
					intent="flat"
					size="sm"
					@click="editor.chain().focus().splitCell().run()">
					Split
				</Button>
				<Button
					intent="flat"
					size="sm"
					@click="editor.chain().focus().toggleHeaderColumn().run()">
					Toggle Header Col
				</Button>
				<Button
					intent="flat"
					size="sm"
					@click="editor.chain().focus().toggleHeaderRow().run()">
					Toggle Header Row
				</Button>
				<Button
					intent="flat"
					size="sm"
					@click="editor.chain().focus().mergeOrSplit().run()">
					Merge/Split
				</Button>
				<Button
					intent="flat"
					size="sm"
					@click="editor.chain().focus().fixTables().run()">
					fixTables
				</Button>
			</div>
		</div>
		<EditorContent
			class="editor-content table-auto"
			:class="theme"
			:editor="editor" />
	</div>
</template>

<script lang="ts" setup>
import { Editor, EditorContent, useEditor } from "@tiptap/vue-3";
import { StarterKit } from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import Image from "@tiptap/extension-image";
import Placeholder from "@tiptap/extension-placeholder";
import { debounce } from "lodash";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Button from "@/components/ui/ButtonLinks/Button.vue";

import Superscript from "@tiptap/extension-superscript";
import Subscript from "@tiptap/extension-subscript";
import { SourceView } from "./SourceView";
import {
	defineComponent,
	onMounted,
	ref,
	watch,
	watchEffect,
	withDefaults,
} from "vue";
import EditorSourceModal from "./modals/EditorSourceModal.vue";
import EditorLinkModal from "./modals/EditorLinkModal.vue";
// import {
//     CustomBulletList,
//     CustomListItem,
// } from "@/components/_elements/tiptap-ext/CustomBulletList";

const props = withDefaults(
	defineProps<{
		modelValue: any;
		placeholder?: string;
		theme?: string;
	}>(),
	{
		modelValue: null,
		placeholder: "Type something...",
		theme: "light",
	}
);

const emit = defineEmits<{
	(event: "update:modelValue", value: string): void;
}>();

const data = ref<{
	linkModalIsActive: boolean;
	linkUrl: string | null;
	showSource: boolean;
	sourceModalIsActive: boolean;
	source: any;
}>({
	linkModalIsActive: false,
	linkUrl: null,
	showSource: true,
	sourceModalIsActive: false,
	source: null,
});

const editor = useEditor({
	extensions: [
		StarterKit,
		Link,
		Underline,
		Image.configure({
			inline: true,
		}),
		Superscript,
		Subscript,
		SourceView.configure({
			onOpenSource: (attrs) => {
				showSourceModal(attrs);
			},
		}),
		Table.configure({
			resizable: true,
		}),
		TableRow,
		TableHeader,
		TableCell,
	],
	editorProps: {
		attributes: {
			class: "",
		},
	},
	// linkModalIsActive: false,
	// linkUrl: null
});

onMounted(() => {
	const updateModel = debounce((value) => {
		emit("update:modelValue", value);
	}, 300);
	editor.value?.on("update", () => {
		updateModel(editor.value?.getHTML());
	});
	editor.value?.commands.setContent(props.modelValue ?? "");
});
function showLinkModal(attrs) {
	data.value.linkUrl = attrs.href;
	data.value.linkModalIsActive = true;
}
function hideLinkModal() {
	data.value.linkUrl = null;
	data.value.linkModalIsActive = false;
	document.getElementById("linkButton")?.focus();
}
function setLinkUrl(url) {
	// cancelled
	if (url === null) {
		return;
	}

	// empty
	if (url === "") {
		editor.value?.chain().focus().extendMarkRange("link").unsetLink().run();

		return;
	}

	// update link
	editor.value
		?.chain()
		.focus()
		.extendMarkRange("link")
		.setLink({ href: url })
		.run();

	hideLinkModal();
}
function showSourceModal(attrs) {
	data.value.source = editor.value?.getHTML() ?? "";
	data.value.sourceModalIsActive = true;
}
function hideSourceModal() {
	data.value.source = null;
	data.value.sourceModalIsActive = false;
}
function updateSource(markup) {
	let current = editor.value?.getHTML();
	if (markup !== current) {
		editor.value?.commands.setContent(markup);
	}
	hideSourceModal();
}
function resetValue() {
	editor.value?.commands.setContent("");
}

watch([props.modelValue], (value) => {
	let current = editor.value?.getHTML();
	if (JSON.stringify(props.modelValue) !== JSON.stringify(current)) {
		editor.value?.commands.setContent(props.modelValue);
	}
});
</script>
<style lang="postcss">
.ProseMirror {
	table {
		border-collapse: collapse;
		table-layout: fixed;
		width: 100%;
		margin: 0;
		overflow: hidden;

		td,
		th {
			min-width: 1em;
			border: 2px solid #ced4da;
			padding: 3px 5px;
			vertical-align: top;
			box-sizing: border-box;
			position: relative;

			> * {
				margin-bottom: 0;
			}
		}

		th {
			font-weight: bold;
			text-align: left;
			background-color: #f1f3f5;
		}

		.selectedCell:after {
			z-index: 2;
			position: absolute;
			content: "";
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: rgba(200, 200, 255, 0.4);
			pointer-events: none;
		}

		.column-resize-handle {
			position: absolute;
			right: -2px;
			top: 0;
			bottom: -2px;
			width: 4px;
			background-color: #adf;
			pointer-events: none;
		}

		p {
			margin: 0;
		}
	}
}

.tableWrapper {
	padding: 1rem 0;
	overflow-x: auto;
}

.resize-cursor {
	cursor: ew-resize;
	cursor: col-resize;
}
</style>
