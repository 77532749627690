<template>
    <div class="mb-3 sm:mb-1 last:mb-0">
        <label
            class="flex sm:inline-flex items-center justify-between sm:justify-start"
        >
            <div class="flex items-center">
                <input
                    type="checkbox"
                    class="form-checkbox text-pear-600"
                    :checked="isChecked"
                    @click="() => handleUpdate(category, !isChecked)"
                />
                <span class="leading-6 mx-2">{{ category.name }}</span>
            </div>
            <div
                class="inline-block bg-gray-700 text-gray-50 rounded leading-5 px-2 text-xs"
            >
                {{ count[category.uuid] }}
            </div>
        </label>
        <div class="ml-10" v-if="category.children && category.children.length > 0" v-for="child in category.children">
            <ExamCategoryItem
                :key="child.uuid"
                :category="child"
                :count="count"
                :selectedCategories="selectedCategories"
                :handleUpdate="handleUpdate"
            />
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: "ExamCategoryItem",
    props: {
        count: Object,
        name: String,
        category: Object,
        handleUpdate: Function,
        selectedCategories: Object,
    },
    data(): any {
    },
    computed: {
      isChecked() {
              return this.selectedCategories.some(c => c.uuid === this.category.uuid)
      }
    },
};
</script>
