<template>
    <div
        class="ml-auto  w-12 h-12 rounded-full"
        :content="buttonTooltipContent"
        v-tippy="{ placement: 'top', theme: isDisabled ? 'warning' : '', interactive: true }"
    >
        <button ref="ceButton"
                type="button"
                @click="ceModal.openModal"
                :disabled="isDisabled"
                class="reset-button">
            <SvgIcon icon="arrow-path" size="md" weight="bold"/>
        </button>
    </div>
    <styled-modal
        ref="ceModal"
        :is-open="modalOpen"
        @close="close"
    >
        <template v-slot:panel="slotProps">
            <div class="sm:flex sm:items-start">
                <!-- ICON -->
                <div v-if="modalState === ModalState.Success"
                     class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10"
                >
                    <SvgIcon class="text-green-600" icon="check"/>
                </div>
                <div v-if="modalState !== ModalState.Success"
                     class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                >
                    <SvgIcon class="text-red-600" icon="warning"/>
                </div>

                <!-- BODY -->
                <div class="text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h2
                        class="mt-2 text-lg leading-6 font-medium text-gray-900"
                        id="modal-headline"
                    >
                        {{ modalContent.heading }}
                    </h2>
                    <p class="text-sm leading-5 text-gray-500">
                        {{ modalContent.body }}
                    </p>
                    <p class="text-sm leading-5 text-gray-500">
                        <b v-if="modalContent.note">NOTE:</b> {{ modalContent.note }}
                    </p>

                    <!-- ACTIONS -->
                    <div class="mt-5 sm:mt-4 sm:flex w-100 justify-end">
                        <div class="mr-3">
                            <button
                                class="inline-flex justify-center w-full rounded-lg border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus-visible:ring transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                                type="button"
                                @click="() => { close(); slotProps.events.closeModal(); }">{{
                                    modalState === ModalState.Ready || modalState === ModalState.Loading ? 'cancel' : 'close'
                                }}
                            </button>
                        </div>
                        <div v-if="modalState === ModalState.Ready">
                            <button
                                class="inline-flex justify-center w-full rounded-lg border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus-visible:ring focus-visible:ring-red-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                                type="button" @click="handleReset">Yes, Reset Course Progress
                            </button>
                        </div>

                        <div v-if="modalState === ModalState.Loading">
                            <button
                                class="inline-flex justify-center w-full rounded-lg border border-gray-300 px-4 py-2 bg-blue-500 text-white leading-6 font-medium text-gray-700 shadow-sm hover:text-white hover:bg-blue-400 focus:outline-none focus:border-blue-300 focus-visible:ring transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                                type="button">
                                <SvgIcon icon="arrow-path" size="sm" weight="bold" class="animate-spin mr-3"/>
                                Resetting
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </styled-modal>
</template>

<script setup lang="ts">
import {ref, computed} from 'vue';
import {User} from "@/types/user.ts";
import {Course} from "@/types";
import SvgIcon from "@/components/_elements/SvgIcon.vue";
import StyledModal from "@/components/_elements/modals/StyledModal.vue";
import axios from "axios";

const props = defineProps<{
    user: User;
    course: Course;
    enrollmentId: string;
    isEligibleForReset: boolean,
    bienniumMonth: string,
    bienniumYear: string,
    remainingAttempts: number;
    hasCertificate: boolean;
}>();

enum ModalState {
    Loading,
    Success,
    Failure,
    Ready,
}

const accountSettingsUrl = `${import.meta.env.VITE_APP_URL}/account/certifications`;
const ceModal = ref(null);
const modalOpen = ref(false);
const modalState = ref(ModalState.Ready);
const isDisabled = ref(!props.isEligibleForReset);
const modalContent = computed(() => {
    switch (modalState.value) {
        case ModalState.Ready:
        case ModalState.Loading:
            return {
                heading: "Reset Course Progress",
                body: "Are you sure you want to reset all course progress, including post-course assessment attempts? This action cannot be undone.",
                note: "You will still be able to access past activity in reporting.",
            }
        case ModalState.Success:
            return {
                heading: "Success",
                body: "Your course progress has been successfully reset!",
                note: null,
            }
        case ModalState.Failure:
            return {
                heading: "Oops, something went wrong",
                body: "There was a problem when attempting to reset your course progress. Please try again later. If you continue to have issues please contact support.",
                note: null,
            }
    }
});

const buttonTooltipContent = computed(() => {
    if (isDisabled.value && (props.remainingAttempts <= 0 || props.hasCertificate) && (!props.bienniumMonth || !props.bienniumYear)) {
        return `Please visit <a class="text-blue-500 hover:text-blue-300" href="${accountSettingsUrl}">account settings</a> and enter your current biennium end date`
    }

    return isDisabled.value
        ? `Reset available starting ${props.bienniumMonth} ${props.bienniumYear} in your next biennium`
        : "Reset course progress";
});
const openModal = () => {
    modalOpen.value = true;
};

const close = () => {
    modalOpen.value = false;
    if (modalState.value === ModalState.Success) {
        window.location.reload();
    }
}

const handleReset = () => {
    modalState.value = ModalState.Loading;
    axios.post(`/api/learn/enrollments/${props.enrollmentId}/reset-progress`)
        .then(r => {
            modalState.value = ModalState.Success;
        })
        .catch(e => {
                modalState.value = ModalState.Failure;
            }
        );
}
</script>

<style scoped>
.reset-button {
    @apply cursor-pointer text-gray-700 border-2 border-gray-200 bg-gray-50 w-12 h-12 flex justify-center items-center rounded-full;
    @apply hover:border-blue-700 hover:text-white hover:bg-blue-700;
    @apply disabled:opacity-50 disabled:hover:bg-gray-50 disabled:hover:border-gray-200 disabled:hover:text-gray-700;
}
</style>

<style>
.tippy-box {
    text-align: center;
}

.tippy-box[data-theme~='warning'] {
    background-color: #BA0101;
    color: white;
}

.tippy-box[data-theme~='warning'] .tippy-arrow {
    color: #BA0101;
}
</style>
