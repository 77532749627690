<template>
	<div class="exam-question bg-white rounded-md shadow w-full">
		<div
			class="flex items-center justify-between bg-gray-50 border-b border-gray-100 rounded-t-md p-4 sm:p-8">
			<h2
				class="flex items-center uppercase font-medium text-sm"
				:class="themeColor">
				<SvgIcon
					v-if="!review && options[responseStatus].icon"
					class="mr-2"
					:icon="themeIcon"
					size="base" />
				<div>
					<span class="hidden sm:inline">Question </span>
					{{ position }} / {{ totalQuestions }}
				</div>
			</h2>
			<PrepExamFlagQuestion
				v-if="editable && !isLearnExam"
				:question="questionId"
				:isFlagged="questionFlagged" />
		</div>
		<div class="p-4 sm:p-8" v-if="paused">
			<div
				class="flex items-center justify-center bg-red-50 rounded-md h-48">
				<div>
					<div class="text-xl font-medium leading-6 mb-1">
						Exam is paused
					</div>
					<div>
						In order to continue with your exam, please click the
						&lsquo;play&rsquo; button
					</div>
				</div>
			</div>
		</div>
		<div class="p-4 sm:p-8" v-if="!paused">
			<div
				style="
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				">
				<div
					class="text-xl font-medium leading-6"
					style="
						-webkit-user-select: none;
						-moz-user-select: none;
						-ms-user-select: none;
						user-select: none;
					"
					v-html="question"></div>
				<div
					class="inline-block bg-gray-50 rounded shadow-lg mt-4 sm:mt-8 mb-2 p-4"
					v-if="questionImage">
					<img
						class="border border-gray-100 max-w-full"
						:src="questionImage"
						alt="" />
				</div>
				<div
					class="mt-6"
					style="
						-webkit-user-select: none;
						-moz-user-select: none;
						-ms-user-select: none;
						user-select: none;
					">
					<div v-for="answer in answers">
						<label
							class="inline-flex items-start text-lg mb-2"
							style="
								-webkit-user-select: none;
								-moz-user-select: none;
								-ms-user-select: none;
								user-select: none;
							">
							<div
								class="flex items-center flex-shrink-0 w-5 h-6 mr-3"
								v-if="set || props.review">
								<SvgIcon
									v-if="answer.checked"
									class="text-green-700"
									icon="check"
									size="base"
									:is-hidden="false"
									role="img"
									aria-label="Correct Answer" />
								<SvgIcon
									v-if="
										!answer.checked &&
										inResponse(answer.uuid)
									"
									class="text-red-700"
									icon="wrong"
									size="base"
									:is-hidden="false"
									role="img"
									aria-label="Incorrect Answer" />
							</div>
							<div class="flex items-center h-6">
								<input
									v-if="hasMultipleAnswers"
									class="flex items-center flex-shrink-0 mr-3 form-checkbox w-5 h-5"
									:class="{
										'text-gray-400': set || !editable,
										'text-blue-600': !set && editable,
									}"
									:disabled="set || !editable"
									type="checkbox"
									v-model="response"
									:value="answer.uuid" />
								<input
									v-if="!hasMultipleAnswers"
									class="flex items-center flex-shrink-0 mr-3 form-radio w-5 h-5"
									:class="{
										'text-gray-400': set || !editable,
										'text-blue-600': !set && editable,
									}"
									:disabled="set || !editable"
									type="radio"
									:checked="response[0] === answer.uuid"
									@input="
										(e) => {
											// @ts-ignore
											response = [e.target.value];
										}
									"
									:value="answer.uuid" />
							</div>
							<div
								class="flex-grow leading-6"
								style="
									-webkit-user-select: none;
									-moz-user-select: none;
									-ms-user-select: none;
									user-select: none;
								"
								v-html="answer.answer"></div>
						</label>
					</div>
				</div>
				<div v-if="!set && editable" class="flex items-end h-10">
					<fade classes="" :show="response.length > 0">
						<button
							type="button"
							class="bg-gray-50 hover:bg-gray-100 border border-gray-100 text-xs leading-7 rounded-lg px-4"
							@click="clear">
							Clear response
						</button>
					</fade>
				</div>
				<fade classes="" :show="feedback !== null && feedback !== ''">
					<div
						class="mt-4 sm:mt-8 p-4 bg-gray-100 border border-gray-200 rounded-md"
						v-show="feedback != null && feedback !== ''">
						<h4 class="text-xs font-bold uppercase mb-2">
							Feedback
						</h4>
						<div
							class="prose"
							ref="feedbackRef"
							v-html="feedback" />
					</div>
				</fade>
				<div class="mt-8">
					<div class="text-sm font-bold mb-2" v-if="category">
						Category
					</div>
					<div class="flex items-center justify-between">
						<div>
							<PrepCategoryBadge
								v-if="category"
								:category="category"
								:primary="true" />
						</div>
						<div v-if="!review || isLearnExam" class="text-sm">
							<ExamFeedback
								:questionType="questionType"
								:question="questionUuid" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="flex flex-wrap gap-2 items-center justify-between bg-gray-50 border-t border-gray-100 rounded-b-md p-4 sm:p-8">
			<div class="">
				<a
					class="flex items-center justify-center bg-transparent hover:bg-gray-600 text-gray-600 hover:text-gray-50 border border-gray-600 hover:border-transparent transition duration-200 rounded-full w-9 h-9"
					:href="path + (position - 1)"
					v-if="position > 1"
					aria-label="Previous Question">
					<SvgIcon
						icon="arrow-left"
						size="xs"
						weight="extra-bold"
						:is-hidden="false" />
				</a>
				<button
					type="button"
					class="flex items-center justify-center bg-transparent cursor-not-allowed text-gray-300 border border-gray-300 rounded-full w-9 h-9"
					v-if="position === 1"
					aria-label="No Previous Questions"
					disabled>
					<SvgIcon
						icon="arrow-left"
						size="xs"
						weight="extra-bold"
						:is-hidden="false" />
				</button>
			</div>
			<div class="flex-1 flex justify-center">
				<div class="flex items-center justify-start md:justify-center">
					<template v-if="takingQuiz && response.length > 0">
						<button
							type="button"
							class="main button leading-6 w-40"
							@click="respond">
							Submit Answer
						</button>
					</template>
					<template>
						<button
							v-if="takingQuiz && response.length === 0"
							type="button"
							class="disabled main button leading-6 w-40">
							Submit Answer
						</button>
					</template>
					<template v-if="finishingQuiz && response.length > 0">
						<button
							type="button"
							class="main button leading-6 w-40"
							@click="respond">
							Complete Exam
						</button>
					</template>
					<template v-if="finishingQuiz && response.length === 0">
						<button
							type="button"
							class="disabled main button leading-6 w-40">
							Complete Exam
						</button>
					</template>
					<template v-if="canSubmitExam && response.length > 0">
						<button
							type="button"
							class="main button leading-6 w-40"
							@click="submitExamInstance">
							Complete Exam
						</button>
					</template>
					<template v-if="canSubmitExam && response.length === 0">
						<button
							type="button"
							class="disabled main button leading-6 w-40">
							Complete Exam
						</button>
					</template>
					<template v-if="canSeeResults">
						<a
							class="main button leading-6 text-center w-40"
							:href="resultsPath">
							See Results
						</a>
					</template>
				</div>
			</div>
			<div class="">
				<a
					class="flex items-center justify-center float-right bg-transparent hover:bg-gray-600 text-gray-600 hover:text-white border border-gray-600 hover:no-underline transition duration-200 rounded-full w-20 h-10"
					v-if="nextAnchorShouldShow"
					:href="path + (position + 1)">
					<div class="text-xs uppercase mr-2">
						{{ advanceLabel }} <span class="sr-only">Question</span>
					</div>
					<SvgIcon icon="arrow-right" size="xs" weight="extra-bold" />
				</a>
				<button
					type="button"
					class="flex items-center justify-center float-right bg-transparent cursor-not-allowed text-gray-300 border border-gray-300 rounded-full w-20 h-10"
					v-if="position === totalQuestions">
					<div class="text-xs uppercase mr-2">Next</div>
					<SvgIcon icon="arrow-right" size="xs" weight="extra-bold" />
				</button>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import axios from "axios";
import debounce from "debounce";
import { defineProps, computed, onMounted, watch, ref } from "vue";
import { usePrepExamStore } from "../../../stores/prepStore";
import { storeToRefs } from "pinia";
import $events from "../../../event-bus";

const prepStore = usePrepExamStore();
const {
	paused,
	answers,
	correct,
	feedback,
	percentComplete,
	response,
	responses,
	score,
	set,
	status,
	toast,
} = storeToRefs(prepStore);

watch(feedback, () => {
	setFeedbackLinkTarget();
});

// export default {
//     watch: {
//         getResponse: {
//             handler(value) {
//                 this.response = value
//             },
//             deep: true,
//             immediate: true
//         },
//         getPercentComplete(value) {
//             this.percentComplete = value
//         },
//         response(value) {
//             if (!this.showFeedback) {
//                 this.respond()
//             }
//         }
//     }
// }
watch(response, (value) => {
	if (!props.showFeedback) {
		respond();
	}
});

const feedbackRef = ref();

const props = defineProps({
	type: {
		type: String,
		required: false,
		default: "prepExam",
	},
	reviewAnswers: {
		type: Array,
		required: false,
		default: () => [],
	},
	reviewFeedback: {
		type: String,
		default: "",
	},
	examInstanceUuid: {
		type: String,
		required: false,
	},
	category: Object,
	correct: Boolean,
	courseTree: Object,
	editable: Boolean,
	exam: String,
	hasMultipleAnswers: Boolean,
	lastQuestion: Boolean,
	position: {
		type: Number,
		required: true,
	},
	question: String,
	questionFlagged: Boolean,
	questionId: Number,
	questionUuid: String,
	questionImage: String,
	getPercentComplete: Number,
	getResponse: Array,
	review: {
		type: Boolean,
		default: false,
	},
	showFeedback: Boolean,
	status: String,
	totalQuestions: Number,
	examPath: {
		type: String,
		required: false,
	},
	resultsPath: {
		type: String,
		required: false,
	},
	reviewPath: {
		type: String,
		required: false,
	},
});

const formStatus = ref("ready");
const options = ref({
	correct: {
		color: "text-green-700",
		icon: "check",
	},
	incorrect: {
		color: "text-red-700",
		icon: "wrong",
	},
	default: {
		color: "text-gray-700",
		icon: null,
	},
});

const advanceLabel = computed(() => {
	if (props.review) {
		return "Next";
	}
	return props.showFeedback && !set.value ? "Skip" : "Next";
});

const allQuestionsAnswered = computed(() => {
	return percentComplete.value === 100;
});

const examResolved = computed(() => {
	return ["completed", "expired"].includes(props.status ?? "");
});

const isLearnExam = computed(() => {
	return (
		props.type === "post-course" ||
		props.type === "assessment" ||
		props.type === "quiz"
	);
});

const nextAnchorShouldShow = computed(() => {
	if (props.position === props.totalQuestions) {
		return false;
	}
	return true;
});
const takingQuiz = computed(() => {
	return (
		!props.lastQuestion &&
		!examResolved.value &&
		!set.value &&
		props.showFeedback &&
		formStatus.value === "ready" &&
		props.editable
	);
});
const finishingQuiz = computed(() => {
	return (
		props.lastQuestion &&
		!examResolved.value &&
		!set.value &&
		props.showFeedback &&
		formStatus.value === "ready" &&
		props.editable
	);
});
const canSubmitExam = computed(() => {
	return (
		allQuestionsAnswered.value && !props.showFeedback && !examResolved.value
	);
});
const canSeeResults = computed(() => {
	return isLearnExam.value && allQuestionsAnswered.value && set.value;
});
const shouldSubmitQuiz = computed(() => {
	return props.lastQuestion && isLearnExam.value && props.showFeedback;
});
const onLastQuestion = computed(() => {
	return props.lastQuestion;
});
const path = computed(() => {
	if (props.review) {
		return "/prep/exams/review/" + props.exam + "/";
	}

	if (isLearnExam.value) {
		return props.examPath + "/";
	}

	return "/prep/exams/" + props.exam + "/";
});
const questionType = computed(() => {
	return isLearnExam.value ? "learn" : "prep";
});
const respondRoute = computed(() => {
	if (isLearnExam.value) {
		return "/api/learn/exams/respond";
	}

	return "/api/prep/exams/respond";
});
const responseStatus = computed(() => {
	if (!set.value) {
		return "default";
	}
	switch (correct.value) {
		case true:
			return "correct";
			break;
		case false:
			return "incorrect";
			break;
		default:
			return "default";
	}
});
const selectedAnswers = computed(() => {
	return answers?.value?.filter((o) => response.value.includes(o.uuid));
});
const submitPath = computed(() => {
	if (!isLearnExam.value) {
		return;
	}

	if (props.type === "post-course") {
		return (
			"/api/learn/exams/post-course-results" +
			"/" +
			props.courseTree?.course +
			"/" +
			props.examInstanceUuid
		);
	}

	if (props.type === "assessment") {
		return (
			"/api/learn/exams/assessment-results" +
			"/" +
			props.courseTree?.course +
			"/" +
			props.courseTree?.module +
			"/" +
			props.examInstanceUuid
		);
	}

	return (
		"/api/learn/exams/quiz-results" +
		"/" +
		props.courseTree?.course +
		"/" +
		props.courseTree?.module +
		"/" +
		props.courseTree?.lesson +
		"/" +
		props.examInstanceUuid
	);
});
const themeColor = computed(() => {
	return props.review ? "" : options.value[responseStatus.value].color;
});
const themeIcon = computed(() => {
	return props.review ? "" : options.value[responseStatus.value].icon;
});

const clear = () => {
	response.value = [];
};
const inResponse = (answerId) => {
	return response.value.includes(answerId);
};

const respond = () => {
	if (!["completed", "expired"].includes(props.status ?? "")) {
		formStatus.value = "processing";
		axios
			.post(respondRoute.value, {
				exam: props.exam,
				position: props.position,
				question: props.questionUuid,
				response: response.value,
				selectedAnswers: selectedAnswers.value,
			})
			.then((response) => {
				if (shouldSubmitQuiz.value) {
					return submitExamInstance();
				}

				if (response.data.submit) {
					submit();
					return;
				}

				formStatus.value = "ready";
				toast.value = false;
				correct.value = response.data.correct;
				feedback.value = response.data.feedback;
				answers.value = response.data.answers;
				percentComplete.value = response.data.complete;
				responses.value = response.data.responses;
				score.value = response.data.score;
				set.value = response.data.set;
			});
	}
};

const setFeedbackLinkTarget = () => {
	setTimeout(() => {
		let links = feedbackRef?.value?.getElementsByTagName("a");
		for (let link of links) {
			link.setAttribute("target", "_blank");
		}
	}, 300);
};
const setState = () => {
	percentComplete.value = props.getPercentComplete || percentComplete.value;
};
const submit = () => {
	window.location.assign(path.value + props.position + "/submit");
};
const submitError = () => {
	$events.$emit("openModal", {
		type: "warning",
		heading: "Wait!",
		message: "You must answer all questions before you submit this exam.",
	});
};
const submitExamInstance = () => {
	if (props.courseTree) {
		axios
			.post(submitPath.value ?? "")
			.then((response) => {
				if (props.type === "post-course") {
					window.location.href = props.resultsPath ?? "";
				} else {
					window.location.href =
						props.reviewPath + "/" + props.position;
				}
			})
			.catch((error) => {
				// TODO SHOW MODAL
				// this.$events.$emit('openModal', {
				//     type: 'warning',
				//     heading: 'Error',
				//     message: 'We encountered a problem submitting your quiz.'
				// });
			});
	} else {
		submit();
	}
};
onMounted(() => {
	if (props.reviewAnswers) {
		answers.value = props.reviewAnswers;
	}
	if (props.reviewFeedback) {
		feedback.value = props.reviewFeedback;
	}
	// if (typeof props.correct === "boolean" && props.correct !== null) {
	//     correct.value = props.correct;
	// }
	// if (!this.showFeedback) {
	//     this.respond = debounce(this.respond, 500)
	// }

	// this.$nextTick(() => this.setState())
	// this.$nextTick(() => this.setFeedbackLinkTarget())
});
</script>

<style scoped>
.prose {
	max-width: none;
}
</style>
