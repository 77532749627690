<template>
    <div>
        <div class="bg-white rounded-md shadow">
            <div
                class="border-b border-gray-100 bg-gray-50 py-4 px-4 sm:px-8 rounded-t-md"
            >
                <div class="flex flex-wrap items-center justify-between">
                    <h3
                        class="font-medium text-base sm:text-lg leading-8 sm:leading-12"
                    >
                        Question pool
                    </h3>
                    <div
                        class="flex items-baseline bg-white text-gray-800 border border-gray-100 text-base px-2 sm:px-4 rounded-lg"
                        v-if="categories.length > 0"
                    >
                        <div class="leading-11 my-px">
                            {{ $filters.quantity(questionPoolSize) }}
                        </div>
                        <div
                            class="text-xs sm:text-sm font-medium uppercase ml-1 sm:ml-2"
                        >
                            Questions
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col space-y-8 p-4 sm:p-8">
                <div class="flex items-center">
                    <label class="font-medium leading-11 w-64"
                    >Question bank</label
                    >
                    <SelectDropdown
                        v-model="questionBank"
                        name="question_bank"
                        theme="flat"
                    >
                        <option
                            v-for="(category) in prepExamCategories"
                            :value="category.bankValue"
                            v-text="category.bankLabel"
                        />
                    </SelectDropdown>
                </div>
                <div
                    v-if="assignment && questionBank"
                    class="flex items-center"
                >
                    <label class="font-medium leading-11 w-64">Access</label>
                    <SelectDropdown
                        v-model="questionAccess"
                        theme="flat"
                        variant="access"
                    >
                        <option value="open">Open</option>
                        <option
                            v-if="hasReserveQuestions"
                            value="reserved">Reserved</option>
                    </SelectDropdown>
                    <input
                        type="hidden"
                        name="access"
                        v-model="questionAccess"
                    />
                </div>
                <div
                    v-if="!assignment && questionBank"
                    class="flex items-center"
                >
                    <label class="font-medium leading-11 w-64">Type</label>
                    <SelectDropdown
                        v-model="questionType"
                        name="question_type"
                        theme="flat"
                    >
                        <option value="all">All</option>
                        <option value="incorrect">Incorrect</option>
                        <option value="flagged">Flagged</option>
                    </SelectDropdown>
                </div>
                <div class="flex flex-wrap items-start" v-if="questionBank">
                    <label class="font-medium leading-11 w-64"
                    >Content Categories</label
                    >
                    <div class="flex flex-grow">
                        <div class="border border-gray-100 rounded-md w-full">
                            <div
                                class="even:bg-gray-100 last:rounded-b-md p-6"
                                v-for="category in categories"
                                :key="category.uuid"
                            >
                                <div class="flex flex-wrap items-start">
                                    <h3
                                        class="font-medium leading-6 w-40 xl:w-48 mb-4 sm:mb-0"
                                    >
                                        {{ category.name }}
                                    </h3>
                                    <div>
                                        <ExamCategoryItem
                                            v-for="child in category.children"
                                            :handleUpdate="toggleSelectedCategory"
                                            :selectedCategories="selectedCategories"
                                            :count="counts"
                                            :category="child"
                                            :key="child.uuid"
                                        />
                                    </div>
                                </div>
                            </div>
                            <Loader :show="categories.length == 0"/>
                        </div>
                        <input
                            type="hidden"
                            name="categories"
                            :value="JSON.stringify(categoryPayload)"
                        />
                    </div>
                </div>
                <div class="flex flex-wrap items-start" v-if="questionBank">
                    <div class="flex flex-col items-start">
                        <label class="font-medium leading-11 w-64"
                        >Category breakdown</label
                        >
                        <button
                            v-if="showCategoryReset"
                            type="button"
                            class="bg-gray-100 hover:bg-gray-200 border border-gray-200 hover:border-gray-300 text-sm rounded-lg leading-8 px-3"
                            @click="$events.$emit('resetCategoryBreakdown')"
                        >
                            Reset
                        </button>
                    </div>
                    <div class="flex-grow">
                        <PrepCategorySlider
                            @updateBreakdown="
                                (value) => (breakdown = [...value])
                            "
                            :parents="parents"
                        />
                        <div class="text-gray-500 text-sm text-center mt-8">
                            Percent values are approximate and may not add to
                            100%
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="border-t border-b border-gray-100 bg-gray-50 p-4 sm:p-8"
            >
                <h3 class="font-medium text-lg">Exam options</h3>
            </div>
            <div class="flex flex-col p-4 sm:p-8 space-y-4 sm:space-y-8">
                <div v-if="assignment">
                    <label
                        class="block font-bold text-xs uppercase mb-1"
                        for="name"
                    >Exam Name</label
                    >
                    <input
                        class="leading-5 rounded-lg border p-3 w-full"
                        id="name"
                        name="name"
                        v-model="name"
                        required
                    />
                </div>
                <div class="flex items-center">
                    <div class="flex justify-center w-14">
                        <input
                            v-model="examLength"
                            class="border rounded-lg p-2 w-full text-center"
                            name="questions_total"
                        />
                    </div>
                    <label class="ml-5">Number of questions</label>
                </div>
                <div v-if="!assignment" class="flex items-center">
                    <div class="flex justify-center w-14">
                        <ToggleSwitch v-model="showFeedback" :size="'sm'"/>
                    </div>
                    <label class="ml-5"
                    >Show answers and feedback as you go</label
                    >
                    <input
                        type="hidden"
                        name="show_feedback"
                        v-model.boolean="showFeedback"
                    />
                </div>
                <div class="flex items-center">
                    <div class="flex justify-center w-14">
                        <ToggleSwitch v-model="timed" size="sm"/>
                    </div>
                    <label class="ml-5">Include timer</label>
                    <div
                        v-if="timed"
                        class="ml-6 bg-cyan-100 text-sm font-medium leading-6 px-2"
                    >
                        <span v-html="$filters.elapsed(estimatedTime)"/>
                        allowed
                    </div>
                    <input type="hidden" name="timed" v-model.boolean="timed"/>
                </div>
                <template v-if="assignment && timed">
                    <div class="flex items-center">
                        <div class="flex justify-center w-14">
                            <ToggleSwitch v-model="assignmentPause" size="sm"/>
                        </div>
                        <label class="ml-5">Allow learners to pause exam</label>
                        <input
                            type="hidden"
                            name="assignment_pause"
                            v-model.boolean="assignmentPause"
                        />
                    </div>
                </template>
                <template v-if="!assignment">
                    <div class="flex items-center">
                        <div class="flex justify-center w-14">
                            <ToggleSwitch v-model="saveExam" size="sm"/>
                        </div>
                        <label class="ml-5">
                            Save this configuration to your
                            <a class="cta" href="/prep/exams/saved"
                            >saved exams</a
                            >
                        </label>
                        <input
                            v-model.boolean="saveExam"
                            type="hidden"
                            name="save_exam"
                        />
                    </div>
                    <div v-if="saveExam">
                        <label
                            class="block font-bold text-xs uppercase mb-1"
                            for="name"
                        >Configuration Name</label
                        >
                        <input
                            class="leading-5 rounded-lg border p-3 w-full"
                            id="name"
                            name="name"
                            v-model="name"
                            autofocus
                            required
                            placeholder="My Saved Exam #1"
                        />
                    </div>
                </template>
            </div>
            <div
                class="bg-gray-50 border-t border-gray-100 py-2 px-4 sm:py-6 sm:px-8 rounded-b-md"
            >
                <input type="hidden" name="type" v-model="type"/>
                <input
                    type="hidden"
                    name="question_type"
                    v-model="questionType"
                    v-if="assignment"
                />
                <input
                    type="hidden"
                    name="assignment_attempts"
                    v-model="assignmentAttempts"
                />
                <div class="flex flex-wrap items-center space-x-8">
                    <button
                        class="primary button leading-6 my-2 w-48"
                        type="button"
                        @click="submit"
                        v-text="submitText"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import {mapState} from "vuex";
import {defineComponent} from "vue";
import categories from "@/components/Prep/categories/Categories.vue";

export default defineComponent({
    props: {
        assignment: {
            type: Boolean,
            default: false,
        },
        categoryOptions: Object,
        timePerQuestion: Number,
        defaultBank: String,
    },
    data(): any {
        return {
            assignmentAttempts: 1,
            assignmentAssignee: null,
            assignmentPause: false,
            breakdown: [],
            categories: [],
            counts: [],
            name: "",
            prepExamCategories: [
                {
                    bankLabel: '2024 Radiography (R)',
                    bankValue: '2024 Radiography'
                },
                {
                    bankLabel: '2024 Limited Scope of Practice Radiography',
                    bankValue: '2024 Limited Scope of Practice Radiography'
                },
                {
                    bankLabel: 'Computed Tomography (CT)',
                    bankValue: 'CT'
                },
                {
                    bankLabel: 'Magnetic Resonance Imaging (MRI)',
                    bankValue: 'Magnetic Resonance Imaging'
                },
                {
                    bankLabel: 'California Radiography Supervisor and Operator Examination',
                    bankValue: 'California Radiography Supervisor and Operator Examination'
                },
                {
                    bankLabel: 'Certified Medication Aide',
                    bankValue: 'Medication Aide Certification 2024'
                },
            ],
            banksWithReserveQuestions: ['2024 Radiography', '2024 Limited Scope of Practice Radiography'],
            questionAccess: "open",
            questionBank: this.defaultBank,
            questionType: "all",
            showFeedback: false,
            timed: false,
            type: "build",
            saveExam: false,
            showAssignmentModal: false,
            showCategoryReset: false,
            defaultExamLength: 25,
            examLength: this.defaultExamLength,
            selectedCategories: [],
            status: "ready",
        };
    },
    computed: {
        ...mapState(["user"]),
        subCategories () {
          return this.categories.map(cat => cat.children).flat();
        },
        topics() {
            return this.subCategories.map(cat => cat.children).flat();
        },
        hasReserveQuestions() {
          return this.banksWithReserveQuestions.includes(this.questionBank);
        },
        categoryApi() {
            return (
                "/api/prep/exam-categories" +
                "?bank=" +
                this.questionBank +
                "&type=" +
                this.questionType +
                "&access=" +
                this.questionAccess
            );
        },
        categoryPayload() {
            const getSelectedIdsByCategory = (rootCat) => {
                const categoryIds = [];

                const collectSelectedUUIDs = (cat) => {
                    if (cat.children.length > 0 && this.allDescendantsSelected(cat)) {
                        categoryIds.push(cat.uuid);
                    } else if (!cat.children.length && this.isCatSelected(cat)) {
                        categoryIds.push(cat.uuid);
                    } else if (this.hasSelectedDescendant(cat)) {
                        cat.children.forEach(collectSelectedUUIDs);
                    }
                }

                collectSelectedUUIDs(rootCat);
                return categoryIds;
            }

            const processCategory = (category: any, index: number): any => {
                const weight = this.breakdown[index];

                const payload = {
                    weight,
                    categories: getSelectedIdsByCategory(category)
                };

                return payload;
            };

            return this.categories
                .filter((o: any) => this.selectedParentsIds.includes(o.uuid))
                .map((category: any, index: number) =>
                    processCategory(category, index)
                );
        },
        estimatedTime() {
            return this.examLength * this.timePerQuestion;
        },
        questionPoolSize() {
            return this.selectedCategories.reduce((carry, item) => {
                const isSub = this.subCategories.some(c => c.uuid === item.uuid);
                if (!isSub || item.children.length === 0) {
                    return carry + this.counts[item.uuid];
                }
                return carry
            }, 0);
        },
        selectedParents() {
            return this.categories.filter(parent =>
                this.hasSelectedDescendant(parent)
            );
        },
        selectedParentsIds() {
            return this.selectedParents.map((o) => o.uuid);
        },
        submittable() {
            return (
                this.selectedCategories.length > 0 &&
                this.examLength > 0 &&
                (!this.saveExam || (this.saveExam && this.name !== "")) &&
                (!this.assignment || (this.assignment && this.name !== ""))
            );
        },
        submitText() {
            return this.assignment ? "Create exam" : "Start exam";
        },
        parents() {
            let parents = [];
            this.selectedParentsIds.map((parent: any) => {
                // @ts-ignore
                parents.push(this.categoryOptions[this.questionBank][parent]);
            });
            return parents;
        },
    },
    methods: {
        hasSelectedDescendant(category) {
            if (category.children?.some(child =>
                this.selectedCategories.some(c => c.uuid === child.uuid)
            )) {
                return true;
            }

            return category.children?.some(child =>
                this.hasSelectedDescendant(child)
            ) ?? false;
        },
        allDescendantsSelected(category) {
            return category.children?.every(child =>
                this.selectedCategories.some(c => c.uuid === child.uuid && this.allDescendantsSelected(child))
            );
        },
        findParentByChildUUID(data, targetUUID: string) {
            // Helper function to perform recursive search
            const searchNode = (node) => {
                if (node.children && node.children.some(child => child.uuid === targetUUID)) {
                    return node;
                }

                if (node.children) {
                    for (const child of node.children) {
                        const result = searchNode(child);
                        if (result) return result;
                    }
                }

                return null;
            };

            for (const item of data) {
                const result = searchNode(item);
                if (result) return result;
            }

            return null;
        },
        isCatSelected(cat) {
            return this.selectedCategories.some(sc => sc.uuid === cat.uuid);
        },
        toggleSelectedCategory(category, value) {
            const isSub = this.subCategories.some(c => c.uuid === category.uuid);
            const isTopic = this.topics.some(topic => topic.uuid === category.uuid);

            if (isSub) {
                if (value) {
                    if (!this.isCatSelected(category)) {
                        this.selectedCategories.push(category);
                    }
                } else {
                    this.selectedCategories = this.selectedCategories.filter(
                        c => c.uuid !== category.uuid
                    );
                }

                if (category.children?.length > 0) {
                    category.children.forEach(child => {
                        this.toggleSelectedCategory(child, value);
                    });
                }
            }

            if (isTopic) {
                if (value) {
                    if (!this.isCatSelected(category)) {
                        this.selectedCategories.push(category);
                    }

                    const p = this.findParentByChildUUID(this.categories, category.uuid);
                    if (this.allDescendantsSelected(p) && !this.isCatSelected(p)) {
                        this.selectedCategories.push(p);
                    }

                } else {
                    const p = this.findParentByChildUUID(this.categories, category.uuid);

                    if (this.isCatSelected(p)) {
                        this.selectedCategories = this.selectedCategories.filter(
                            c => c.uuid !== p.uuid
                        );
                    }

                    if (this.isCatSelected(category)) {
                        this.selectedCategories = this.selectedCategories.filter(
                            c => c.uuid !== category.uuid
                        );
                    }

                }
            }
        },
		fetch(selectAll = false) {
			this.categories = [];
			this.counts = [];
			axios.get(this.categoryApi).then((response) => {
				this.categories = response.data.categories;
				this.counts = response.data.counts;

				setTimeout(() => {
					this.matchExamLengthToPoolSize();
				}, 50);

				if (selectAll) {
					this.selectAll();
				}
			});
		},
		limitExamLength() {
			if (this.user.permissions.prep_exam_assign) {
				if (this.examLength > 200) {
					this.examLength = 200;
				}
			} else {
				if (this.examLength > 100) {
					this.examLength = 100;
				}
			}
		},
		matchExamLengthToPoolSize() {
			if (this.questionPoolSize < this.examLength) {
				this.examLength = this.questionPoolSize;
			}
		},
		selectAll() {
            const subs = this.categories.flatMap(
                (o) => o.children
            );
		    const topics = subs.flatMap(s => s.children)
            this.selectedCategories = [...subs, ...topics]
		},
		submit() {
			if (this.submittable) {
				setTimeout(() => {
					this.status = "clicked";
					// @ts-ignore
					document.getElementById("examPrepForm").submit();
				}, 50);
			} else {
				this.warn();
			}
		},
		warn() {
			let message = 'Please fix the following issues</p><p class="mt-4">';

			if (this.selectedCategories.length == 0) {
				message = message + "• No category selected<br>";
			}

			if (this.examLength == 0) {
				message =
					message + "• Exam should be more than 0 questions<br>";
			}

			if (this.saveExam && this.name == "") {
				message = message + "• Exam configuration not named<br>";
			}

			if (this.assignment && this.name == "") {
				message = message + "• Exam not named<br>";
			}

			this.$events.$emit("openModal", {
				type: "warning",
				heading: "Hold up!",
				message: message,
			});
		},
	},
	mounted() {
		this.questionBank = this.defaultBank;

		if (this.assignment) {
			this.type = "assign";
			this.timed = true;
		}

		this.$events.$on("showCategoryReset", () => {
			this.showCategoryReset = true;
		});

		this.$events.$on("hideCategoryReset", () => {
			setTimeout(() => (this.showCategoryReset = false), 100);
		});
		this.fetch(true);
	},
	watch: {
        questionPoolSize() {
            if (this.questionPoolSize < this.examLength) {
                this.examLength = this.questionPoolSize;
            } else {
                this.examLength = this.defaultExamLength;
            }
        },
		examLength() {
			this.matchExamLengthToPoolSize();
			this.limitExamLength();
		},
		questionBank(newValue, oldValue) {
			if (newValue !== oldValue) {
                if (!this.hasReserveQuestions) {
                    this.questionAccess = 'open';
                }
				this.fetch(true);
			}
		},
		questionType(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.fetch();
			}
		},
		questionAccess(newValue, oldValue) {
			if (newValue !== oldValue && this.hasReserveQuestions) {
				this.fetch();
			}
		},
	},
});
</script>
