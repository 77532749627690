<template>
	<div>
		<button
			class="flex items-center justify-center bg-white shadow rounded-lg w-9 h-9"
			type="button"
			v-tippy
			content="Favorite"
			@click="submit">
			<SvgIcon
				:class="{
					'text-yellow-300': isFavorited,
					'text-gray-600': !isFavorited,
				}"
				icon="star"
				size="sm"
				:type="iconType" />
			<span class="sr-only">Add to Favorites</span>
		</button>
	</div>
</template>

<script lang="ts">
import axios from "axios";

export default {
	name: "FavoriteItem",
	props: {
		enrollmentId: {
			required: true,
		},
		folder: {
			required: true,
		},
		id: {
			required: true,
		},
		lessonId: {
			required: true,
		},
		moduleId: {
			required: true,
		},
		savedItem: {
			required: false,
		},
		type: {
			required: true,
		},
	},
	data(): any {
		return {
			internalSavedItem: this.savedItem,
		};
	},
	computed: {
		isFavorited() {
			return this.internalSavedItem?.id;
		},
		iconType() {
			return this.isFavorited ? "fill" : "line";
		},
	},
	methods: {
		removeItem() {
			axios
				.delete(
					`/api/learn/enrollments/${this.enrollmentId}/saved-items-folders/${this.folder.id}/items/${this.internalSavedItem.id}`
				)
				.then(() => {
					this.internalSavedItem = null;
				});
		},
		saveItem() {
			axios
				.post(
					`/api/learn/enrollments/${this.enrollmentId}/saved-items-folders/${this.folder.id}/items`,
					{
						id: this.id,
						lesson_id: this.lessonId,
						module_id: this.moduleId,
						type: this.type,
					}
				)
				.then((response) => {
					this.internalSavedItem = response.data;
				});
		},
		submit() {
			this.isFavorited ? this.removeItem() : this.saveItem();
		},
	},
};
</script>
