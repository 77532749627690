<template>
	<div>
		<div v-if="editor" class="tiptap-toolbar" :class="theme">
			<button
				type="button"
				:class="{ 'is-active': editor.isActive('bold') }"
				:disabled="!editor.can().chain().focus().toggleBold().run()"
				@click="editor.chain().focus().toggleBold().run()"
				aria-label="Bold"
				:aria-pressed="editor.isActive('bold') ? 'true' : 'false'">
				<img src="/svg/editor/bold.svg" alt="" />
			</button>
			<button
				type="button"
				:class="{ 'is-active': editor.isActive('italic') }"
				:disabled="!editor.can().chain().focus().toggleItalic().run()"
				aria-label="Italic"
				@click="editor.chain().focus().toggleItalic().run()"
				:aria-pressed="editor.isActive('italic') ? 'true' : 'false'">
				<img src="/svg/editor/italic.svg" alt="" />
			</button>
			<button
				type="button"
				:class="{ 'is-active': editor.isActive('underline') }"
				:disabled="
					!editor.can().chain().focus().toggleUnderline().run()
				"
				aria-label="Underline"
				@click="editor.chain().focus().toggleUnderline().run()"
				:aria-pressed="editor.isActive('underline') ? 'true' : 'false'">
				<img src="/svg/editor/underline.svg" alt="" />
			</button>
			<button
				type="button"
				:class="{ 'is-active': editor.isActive('superscript') }"
				:disabled="
					!editor.can().chain().focus().toggleSuperscript().run()
				"
				@click="editor.chain().focus().toggleSuperscript().run()"
				aria-label="Superscript"
				:aria-pressed="
					editor.isActive('superscript') ? 'true' : 'false'
				">
				<img src="/svg/editor/superscript.svg" alt="" />
			</button>
			<button
				type="button"
				:class="{ 'is-active': editor.isActive('subscript') }"
				:disabled="
					!editor.can().chain().focus().toggleSubscript().run()
				"
				@click="editor.chain().focus().toggleSubscript().run()"
				aria-label="Subscript"
				:aria-pressed="editor.isActive('subscript') ? 'true' : 'false'">
				<img src="/svg/editor/subscript.svg" alt="" />
			</button>
			<div class="spacer"></div>
			<button
				id="linkButton"
				type="button"
				:class="{ 'is-active': editor.isActive('link') }"
				@click="showLinkModal(editor.getAttributes('link'))"
				aria-label="Link"
				:aria-pressed="editor.isActive('link') ? 'true' : 'false'">
				<img src="/svg/icons/link.svg" alt="" />
			</button>
			<!--                <button type="button" :class="{ 'is-active': isActive.link() }"-->
			<!--                        @click="showLinkModal(getMarkAttrs('link'))">-->
			<!--                    <img src="/svg/icons/link.svg" alt="">-->
			<!--                </button>-->
			<!--                // change link-->
			<!--            <button type="button" :class="{ 'is-active': editor.isActive('link') }"-->
			<!--                    :disabled="!editor.can().chain().focus().toggleLink().run()"-->
			<!--                    @click="showLinkModal(getMarkAttrs('link'))">-->
			<!--                <img src="/svg/icons/link.svg" alt="">-->
			<!--            </button>-->
			<div class="spacer"></div>
			<button
				type="button"
				:class="{ 'is-active': editor.isActive('bullet_list') }"
				:disabled="
					!editor.can().chain().focus().toggleBulletList().run()
				"
				@click="editor.chain().focus().toggleBulletList().run()"
				aria-label="Bulleted List">
				<img src="/svg/icons/ul.svg" alt="" />
			</button>
			<button
				type="button"
				:class="{ 'is-active': editor.isActive('ordered_list') }"
				:disabled="
					!editor.can().chain().focus().toggleOrderedList().run()
				"
				@click="editor.chain().focus().toggleOrderedList().run()"
				aria-label="Ordered List">
				<img src="/svg/icons/ol.svg" alt="" />
			</button>
			<EditorLinkModal
				:active="linkModalIsActive"
				v-model="linkUrl"
				@close="hideLinkModal"
				@confirm="setLinkUrl(linkUrl)" />
			<EditorSourceModal
				:active="sourceModalIsActive"
				v-model="source"
				@close="hideSourceModal"
				@confirm="updateSource" />
		</div>
		<EditorContent
			class="editor-content"
			:class="theme"
			:editor="editor"
			aria-label="Lesson Notes"
			@keydown="" />
	</div>
</template>

<script lang="ts">
import { Editor, EditorContent } from "@tiptap/vue-3";
import { StarterKit } from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import Image from "@tiptap/extension-image";
import Placeholder from "@tiptap/extension-placeholder";
import { debounce } from "lodash";

import Superscript from "@tiptap/extension-superscript";
import Subscript from "@tiptap/extension-subscript";
import { SourceView } from "./SourceView";
import { defineComponent } from "vue";
import EditorSourceModal from "./modals/EditorSourceModal.vue";
import EditorLinkModal from "./modals/EditorLinkModal.vue";

export default defineComponent({
	components: {
		EditorLinkModal,
		EditorSourceModal,
		EditorContent,
	},
	props: {
		modelValue: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			defalt: "",
		},
		theme: String,
	},
	data(): any {
		return {
			editor: null,
			linkModalIsActive: false,
			linkUrl: null,
			showSource: true,
			sourceModalIsActive: false,
			source: null,
		};
	},
	methods: {
		showLinkModal(attrs) {
			this.linkUrl = attrs.href;
			this.linkModalIsActive = true;
			document.body.classList.add("no-scroll");
		},
		hideLinkModal() {
			this.linkUrl = null;
			this.linkModalIsActive = false;
			document.getElementById("linkButton")?.focus();
			document.body.classList.remove("no-scroll");
		},
		setLinkUrl(url) {
			// cancelled
			if (url === null) {
				return;
			}

			// empty
			if (url === "") {
				this.editor
					.chain()
					.focus()
					.extendMarkRange("link")
					.unsetLink()
					.run();

				return;
			}

			// update link
			this.editor
				.chain()
				.focus()
				.extendMarkRange("link")
				.setLink({ href: url })
				.run();

			this.hideLinkModal();
		},
		showSourceModal(attrs) {
			this.source = this.editor.getHTML();
			this.sourceModalIsActive = true;
		},
		hideSourceModal() {
			this.source = null;
			this.sourceModalIsActive = false;
		},
		updateSource(markup) {
			let current = this.editor.getHTML();
			if (markup !== current) {
				this.editor.commands.setContent(markup);
			}
			this.hideSourceModal();
		},
		resetValue() {
			this.editor.commands.setContent("");
		},
	},
	mounted() {
		this.editor = new Editor({
			extensions: [
				StarterKit,
				Link,
				Underline,
				Image.configure({
					inline: true,
				}),
				Superscript,
				Subscript,
				SourceView.configure({
					onOpenSource: () => {
						this.showSourceModal();
					},
				}),
			],
			// linkModalIsActive: false,
			// linkUrl: null
		});
		const updateModel = debounce((value) => {
			this.$emit("update:modelValue", value);
		}, 300);
		this.editor.commands.setContent(this.modelValue);
		this.editor.on("update", () => {
			updateModel(this.editor.getHTML());
		});
	},
	watch: {
		modelValue() {
			let current = this.editor.getHTML();
			if (this.modelValue !== current) {
				this.editor.commands.setContent(this.modelValue);
			}
		},
	},
	beforeDestroy() {
		this.editor.destroy();
	},
});
</script>
<style>
/* Basic editor styles */
.ProseMirror {
	> * + * {
		margin-top: 0.75em;
	}

	img {
		max-width: 100%;
		height: auto;

		& .ProseMirror-selectednode {
			outline: 3px solid #68cef8;
		}
	}
}
</style>
