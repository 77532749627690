<template>
	<BillingSection title="Invoice History">
		<Loader v-if="loading" />
		<template v-if="!loading && paidInvoices.length == 0">
			<div class="text-gray-500">No invoice history</div>
		</template>
		<template v-if="!loading && paidInvoices.length > 0">
			<div class="flex flex-col">
				<div class="overflow-x-auto">
					<div class="inline-block min-w-full pb-3 align-middle">
						<div class="overflow-hidden border md:rounded-b-lg">
							<table class="min-w-full divide-y">
								<thead>
									<tr class="bg-gray-50">
										<th
											scope="col"
											class="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
											Date
										</th>
										<th
											scope="col"
											class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
											Product
										</th>
										<th
											scope="col"
											class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
											Amount
										</th>
										<th
											scope="col"
											class="relative py-3.5 pl-3 pr-4 sm:pr-6">
											<span class="sr-only">Receipt</span>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="invoice in paidInvoices">
										<td
											class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
											<div
												v-text="
													$filters.dateTime(
														invoice.created
													)
												" />
										</td>
										<td
											class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
											<div
												v-text="
													invoice.lines.data[0]
														.description
												" />
										</td>
										<td
											class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
											<div
												v-text="
													$filters.currency(
														invoice.total / 100
													)
												" />
										</td>
										<td
											class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
											<a
												v-if="
													invoice.hosted_invoice_url
												"
												class="cta flex items-center space-x-2"
												:href="
													invoice.hosted_invoice_url
												"
												target="_blank">
												<span>Receipt</span>
												<SvgIcon
													icon="external-link"
													size="sm"
													weight="bold" />
											</a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</template>
	</BillingSection>
</template>

<script lang="ts">
import axios from "axios";
import { mapState } from "vuex";
import BillingSection from "./BillingSection.vue";

export default {
	components: { BillingSection },
	props: {
		customer: {
			type: Object,
		},
	},
	data(): any {
		return {
			invoices: [],
			loading: true,
		};
	},
	computed: {
		...mapState(["user"]),
		paidInvoices() {
			return this.invoices.filter((i) => i.paid);
		},
	},
	methods: {
		getInvoices() {
			axios
				.get(`/api/users/${this.user.uuid}/invoices`)
				.then((response) => {
					this.loading = false;
					this.invoices = response.data;
				});
		},
	},
	mounted() {
		this.getInvoices();
	},
};
</script>
