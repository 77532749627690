<script setup lang="ts">
import {
	ref,
	computed,
	defineProps,
	onMounted,
	onUnmounted,
	watch,
	PropType,
} from "vue";
import CourseCard from "../CourseCard.vue";
// on next pressed

// query for all children.
// for each child get x offset by using getBoundingClientRect()
// [0, 300, 600, 900, 1200]
// on next clicked determine which breakpoint we are in and go to the next one.
// we know the current offset of the container

// a list of all course cards dom elements
const cardRefs = ref<any[]>([]);
const sliderContainerRef = ref<HTMLDivElement | null>(null);

const props = defineProps({
	enrollments: {
		type: Array as PropType<any[]>,
		required: true,
	},
});

const next = () => {
	// get scroll x position for sliderContainerRef
	let scrollX = sliderContainerRef.value?.scrollLeft;

	// we want to loop through each cardRef and get the offsetLeft
	// if the offsetLeft is greater than the scrollX position
	// we want to set the scrollLeft to that offsetLeft
	// we want to break out of the loop once we find the first one.
	let nextCardOffsetLeft = 0;
	for (let i = 0; i < cardRefs.value.length; i++) {
		let cardRef = cardRefs.value[i];
		let offsetLeft = cardRef.getOffsetLeft();
		if (offsetLeft > (scrollX ?? 0)) {
			nextCardOffsetLeft = offsetLeft;
			break;
		}
	}
	sliderContainerRef.value?.scrollTo({
		left: nextCardOffsetLeft,
		top: 0,
		behavior: "smooth",
	});
};

const prev = () => {
	// get scroll x position for sliderContainerRef
	let scrollX = sliderContainerRef.value?.scrollLeft;

	// we want to loop through each cardRef and get the offsetLeft
	// if the offsetLeft is greater than the scrollX position
	// we want to set the scrollLeft to that offsetLeft
	// we want to break out of the loop once we find the first one.
	let prevCardOffsetLeft = 0;
	for (let i = cardRefs.value.length - 1; i >= 0; i--) {
		let cardRef = cardRefs.value[i];
		let offsetLeft = cardRef.getOffsetLeft();
		if (offsetLeft < (scrollX ?? 0)) {
			prevCardOffsetLeft = offsetLeft;
			break;
		}
	}
	sliderContainerRef.value?.scrollTo({
		left: prevCardOffsetLeft,
		top: 0,
		behavior: "smooth",
	});
};

// Calculate if the sliderContainerRef has overflow
const hasOverflow = ref(false);

const calculateHasOverflow = () => {
	let sliderContainer = sliderContainerRef.value;
	let scrollWidth = sliderContainer?.scrollWidth ?? 0;
	let clientWidth = sliderContainer?.clientWidth ?? 0;
	hasOverflow.value = scrollWidth > clientWidth;
};

onMounted(() => {
	// recalculate on resize
	window.addEventListener("resize", calculateHasOverflow);
	calculateHasOverflow();
});

onUnmounted(() => {
	window.removeEventListener("resize", calculateHasOverflow);
});

watch(sliderContainerRef, () => {
	calculateHasOverflow();
});
</script>
<template>
	<div class="flex items-center justify-between">
		<div>
			<h2
				id="slider-heading"
				class="text-xl font-medium leading-6 text-gray-900 sm:mr-10">
				Recent Courses
			</h2>
		</div>
		<div>
			<nav class="flex space-x-2" v-if="hasOverflow">
				<button
					class="bg-blue-100 rounded flex items-center justify-center w-10 h-10"
					title="Go to the previous slide"
					data-prev
					@click="prev">
					<span class="sr-only">Previous Item</span>
					&#10094;
				</button>
				<button
					class="bg-blue-100 rounded flex items-center justify-center w-10 h-10"
					title="Go to the next slide"
					data-next
					@click="next">
					<span class="sr-only">Next Item</span>
					&#10095;
				</button>
			</nav>
		</div>
	</div>
	<div
		class="grid gap-6 overflow-x-scroll grid-flow-col auto-cols-250 snap-x snap-mandatory scrollbar-hide touch-pan-x pb-4 w-full"
		ref="sliderContainerRef"
		aria-labelledby="slider-heading">
		<CourseCard
			v-for="enrollment in props.enrollments"
			:key="enrollment.id"
			:course="enrollment.course"
			:status="'started'"
			ref="cardRefs" />
	</div>
</template>
