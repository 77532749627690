<template>
	<svg
		class="icon"
		:class="[size, type, weight]"
		@click="$emit('click')"
		:aria-hidden="isHidden">
		<use
			xmlns:xlink="http://www.w3.org/1999/xlink"
			:xlink:href="'/svg/platform-icons.svg#icon-' + icon"></use>
	</svg>
</template>

<script lang="ts">
export default {
	props: {
		icon: {
			type: String,
		},
		size: {
			type: String,
			default: "md",
		},
		type: {
			type: String,
			default: "line",
		},
		weight: {
			type: String,
			default: "normal",
		},
		isHidden: {
			type: Boolean,
			default: true,
		},
	},
};
</script>

<style scoped>
svg,
svg .a {
	fill: none;
	stroke: currentColor;
	stroke-linecap: round;
	stroke-linejoin: round;
}

.icon {
	&.line {
		@apply inline-block stroke-current;
	}

	&.fill {
		@apply inline-block fill-current;
	}

	&.xxs {
		@apply w-2 h-2;
	}
	&.xs {
		@apply w-3 h-3;
	}
	&.sm {
		@apply w-4 h-4;
	}
	&.base {
		@apply w-5 h-5;
	}
	&.md {
		@apply w-6 h-6;
	}
	&.lg {
		@apply w-8 h-8;
	}
	&.xl {
		@apply w-10 h-10;
	}
	&.xl2 {
		@apply w-12 h-12;
	}
	&.xl3 {
		@apply w-16 h-16;
	}
	&.xl4 {
		@apply w-20 h-20;
	}
	&.xl5 {
		@apply w-24 h-24;
	}
	&.xl6 {
		@apply w-32 h-32;
	}

	&.thin {
		stroke-width: 0.5px;
	}
	&.extra-light {
		stroke-width: 0.6px;
	}
	&.light {
		stroke-width: 0.75px;
	}
	&.normal {
		stroke-width: 1px;
	}
	&.medium {
		stroke-width: 1.25px;
	}
	&.semibold {
		stroke-width: 1.5px;
	}
	&.bold {
		stroke-width: 2px;
	}
	&.extra-bold {
		stroke-width: 3px;
	}
	&.black {
		stroke-width: 4px;
	}
}
</style>
