<template>
	<div>
		<button
			class="underline text-sm"
			type="button"
			id="reportQuestionBtn"
			@click="open">
			Report this question
		</button>
		<FeedbackModal
			:question="question"
			:questionType="questionType"
			:active="showModal"
			@close="showModal = false" />
	</div>
</template>

<script lang="ts">
import axios from "axios";

export default {
	props: {
		question: String,
		questionType: String,
	},
	data(): any {
		return {
			showModal: false,
		};
	},
	methods: {
		open() {
			this.showModal = true;
			document.body.classList.add("no-scroll");
		},
	},
};
</script>
