<template>
    <div>
        <div class="flex items-start">
            <img
                class="bg-white p-2 mb-4 border rounded-lg shadow w-2/5"
                :src="image"
                v-if="image"
            />
            <button
                class="bg-gray-900 hover:bg-gray-700 text-gray-50 rounded-full shadow p-2 -ml-5 -mt-3"
                type="button"
                v-if="image"
                @click="image = null"
            >
                <SvgIcon icon="close" size="sm" weight="bold"/>
            </button>
            <button
                class="bg-white hover:bg-gray-50 text-gray-700 rounded-full shadow p-2 -ml-8 mt-6"
                type="button"
                v-if="image"
                @click="showImageBrowser = true"
            >
                <SvgIcon icon="refresh" size="sm" weight="medium"/>
            </button>
        </div>
        <button
            type="button"
            class="transition duration-300 flex items-center bg-gray-100 hover:bg-gray-50 border border-gray-200 text-gray-800 py-2 px-4 text-sm leading-7 rounded-lg"
            @click="showImageBrowser = true"
            v-if="!image"
        >
            <SvgIcon icon="photo" size="sm" weight="medium"/>
            <div class="ml-4">Add Image</div>
        </button>
        <ImageGallery
            :selected-image="selectedImage"
            :active="showImageBrowser"
            :save-url="storageApiPath"
            :delete-url="storageApiPath"
            @close="showImageBrowser = false"
            v-model="image"
        />
    </div>
</template>

<script lang="ts">
import ImageGallery from "@/components/_elements/images/ImageGallery.vue";

export default {
    components: {ImageGallery},
    props: {
        selectedImage: {
            type: String,
            default: ""
        },
        modelValue: {
            type: String,
            default: "",
        },
        storageApiPath: {
            type: String,
            default: "admin/content/images",
        },
    },
    data(): any {
        return {
            image: null,
            showImageBrowser: false,
        };
    },
    watch: {
        modelValue(value) {
            this.image = value;
        },
        image(value) {
            this.$emit("update:modelValue", value);
        },
    },
};
</script>
